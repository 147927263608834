<template>
  <div>
    <el-form :inline="true">
      <el-form-item label="选择分类">
        <el-select v-model="categoryid" placeholder="请选择">
          <el-option-group v-for="group in category" :key="group.id" :label="group.name">
            <el-option
              v-for="item in group.children"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search(categoryid)">查询</el-button>
        <el-button type="primary" @click="add">添加新产品</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="12">
      <el-col
        :span="6"
        v-for="product in products"
        :key="product.ProductID"
        style="margin-top:10px;"
      >
        <el-card shadow="hover">
          <img
            :src="$store.state.baseurl+'/Images/'+product.ProductImgUrl.split(' ')[0]"
            class="image"
          />
          <div style="padding: 14px;">
            <p class="content">{{product.ProductNameChn}}</p>
            <p class="content">{{product.ProductSizeChn}}</p>
            <div style="margin-top: 12px;">
              <el-button type="warning" @click="handleEdit(product.ProductID)">编辑</el-button>
              <el-button
                type="danger"
                @click="handleDelete(product.ProductID,product.ProductNameChn)"
              >删除</el-button>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      category: [],
      categoryid: '',
      products: [],
      cat: {},
      editFormVisible: false
    }
  },
  name: 'Product',
  methods: {
    handleEdit(pid) {
      this.$router.push({ path: '/management/editproduct/' + pid })
    },
    handleDelete(pid, pname) {
      this.$confirm('此操作将永久删除产品"' + pname + '", 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http.post('/product/deleteproduct?id=' + pid).then(res => {
            if (res.data.code != 200) {
              this.$message.error(res.data.errmsg)
            } else {
              this.$message({
                message: '删除成功！',
                type: 'success'
              })
              this.getallproducts();
              this.$router.push('/management/productmanagement')
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        }).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
    },
    search(cid) {
      this.$http
        .get('/product/GetProducts?cid=' + cid)
        .then(res => (this.products = res.data.data)).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
    },
    add() {
      this.$router.push({ path: '/management/addproduct/' })
    },
    getallproducts() {
      this.$http
        .get('/product/GetAllProducts')
        .then(res => (this.products = res.data.data)).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
    },
    getallcats() {
      this.$http
        .get('/category/getallcat')
        .then(res => (this.category = res.data.data)).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
    }
  },
  created() {
    this.getallcats();
    this.getallproducts()
  }
}
</script>
<style scoped>
.image {
  width: 100%;
  display: block;
}

.el-pagination .is-background .el-pager li:not(.disabled) .active {
  background-color: #8a857d !important;
}

.content{
  width: 95%;

    white-space: nowrap;

    text-overflow: ellipsis;
 
    overflow: hidden;
}

</style>